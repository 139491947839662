import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const BASE_URL = process.env.REACT_APP_API_URL;

export const companyInfoApi = createApi({
    reducerPath: 'companyInfoApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/company-info`,
        credentials: 'include',
        prepareHeaders: (headers) => {
            headers.set('Accept', 'application/json')
            return headers
        },
    }),
    tagTypes: ['CompanyInfo'],
    endpoints: (builder) => ({
        getCompanyInfo: builder.query({
            query: (company_id) => `/${company_id}`,
            providesTags: ['CompanyInfo']
        }),
    }),
})

export const { useGetCompanyInfoQuery } = companyInfoApi