import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const BASE_URL = process.env.REACT_APP_API_URL;

export const loginAsConsumerApi = createApi({
    reducerPath: 'loginAsConsumerApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/auto-authorize`,
        credentials: 'include',
        prepareHeaders: (headers) => {
            headers.set('Accept', 'application/json')
            return headers
        },
    }),
    tagTypes: ['LoginAsConsumer'],
    endpoints: (builder) => ({
        loginAsConsumer: builder.mutation({
            query: (data) => ({
                url: '',
                method: 'post',
                body:data
            }),
            invalidatesTags: ['LoginAsConsumer']
        }),
    }),
})

export const { useLoginAsConsumerMutation } = loginAsConsumerApi