import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const BASE_URL = process.env.REACT_APP_API_URL;

export const faqApi = createApi({
    reducerPath: 'faqApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/faqs`,
        credentials: 'include',
        prepareHeaders: (headers) => {
            headers.set('Accept', 'application/json')
            return headers
        },
    }),
    tagTypes: ['FAQ'],
    endpoints: (builder) => ({
        getAllFaq: builder.query({
            query: (company_id) => `/${company_id}`,
            providesTags: ['FAQ']
        })
    }),
})

export const { useGetAllFaqQuery } = faqApi