import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { companyInfoApi } from '../World/Pages/ContactUs/Api/companyInfoApi';
import { contactUsFormApi } from '../World/Components/ContactUsForm/Api/contactUsFormApi';
import { termsApi } from '../Common/Terms/Api/termsApi';
import { privacyPolicyApi } from '../Common/PrivacyPolicy/Api/privacyPolicyApi';
import { faqApi } from '../World/Pages/Faqs/Api/faqApi';
import { homeMessageApi } from '../World/Components/HomeMessage/Api/homeMessageApi';
import { checkCompanyApi } from '../World/Layout/Api/checkCompanyApi';
import { loginAsConsumerApi } from '../World/Pages/LoginAsConsumer/Api/loginAsConsumerApi';
import { loginByQRApi } from '../World/Pages/LoginByQR/Api/loginByQRAPI';

export const store = configureStore({
  reducer: {
    [companyInfoApi.reducerPath]: companyInfoApi.reducer,
    [contactUsFormApi.reducerPath]: contactUsFormApi.reducer,
    [termsApi.reducerPath]: termsApi.reducer,
    [faqApi.reducerPath]: faqApi.reducer,
    [homeMessageApi.reducerPath]: homeMessageApi.reducer,
    [checkCompanyApi.reducerPath]: checkCompanyApi.reducer,
    [privacyPolicyApi.reducerPath]: privacyPolicyApi.reducer,
    [loginAsConsumerApi.reducerPath]: privacyPolicyApi.reducer,
    [loginByQRApi.reducerPath]: privacyPolicyApi.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      companyInfoApi.middleware,
      contactUsFormApi.middleware,
      termsApi.middleware,
      privacyPolicyApi.middleware,
      faqApi.middleware,
      homeMessageApi.middleware,
      checkCompanyApi.middleware,
      loginAsConsumerApi.middleware,
      loginByQRApi.middleware
    ),
})

setupListeners(store.dispatch)