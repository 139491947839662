import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const BASE_URL = process.env.REACT_APP_API_URL;

export const homeMessageApi = createApi({
    reducerPath: 'homeMessageApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/home-message`,
        prepareHeaders: (headers) => {
            headers.set('Accept', 'application/json')
            return headers
        },
    }),
    tagTypes: ['HomeMessage'],
    endpoints: (builder) => ({
        getHomeMessage: builder.query({
            query: (company_id) => `/${company_id}`,
            providesTags: ['HomeMessage']
        })
    }),
})

export const { useGetHomeMessageQuery } = homeMessageApi